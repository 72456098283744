@import '../../node_modules/include-media';
@import '../../node_modules/swiper/swiper-bundle.css';
@import "../../node_modules/@fancyapps/ui/dist/fancybox.css";

@import 'reset';
@import 'variables';
@import 'utils';
@import 'global';

@import 'snippets/menu';
@import 'snippets/logo';
@import 'snippets/exhibition-header';
@import 'snippets/gallery';
@import 'snippets/lightbox';

@import 'snippets/blocks/section';
@import 'snippets/blocks/text';
@import 'snippets/blocks/heading';
@import 'snippets/blocks/image';
@import 'snippets/blocks/list';
@import 'snippets/blocks/pagepreview';
@import 'snippets/blocks/button';
@import 'snippets/blocks/newsletter';

@import 'components/text';
@import 'components/footnotes';
@import 'components/menu-button';
@import 'components/footer';

@import 'vendor_ext/fancybox';

@import 'pages/program';
@import 'pages/program-element';
@import 'pages/home';