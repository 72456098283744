html, body {
    font-family: Arial, Helvetica, sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
    word-break: break-word;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

html {
    height: 100%;

    // @include media('>phone') {
    //     background-color: red;
    // }

    // @include media('>tablet') {
    //     background-color: green;
    // }

    // @include media('>desktop') {
    //     background-color: aqua;
    // }
    background-color: var(--col-white);
    color: var(--col-black);
}

body {
    min-height: 100%;
    font-size: 120%;
    line-height: 1.1;
    
    @include media('>tablet') {
        grid-template-columns: 1fr 4fr;
    }
}

main {
    padding: var(--gutter);
    padding-top: 3em;
    overflow-x: hidden;
    min-height: calc(100vh - 45px - (2*var(--gutter)));
    
    @include media ('>tablet') {
        overflow-x: visible;
        padding-top: var(--gutter);
        max-width: 40%;
        margin-left: 30%;
    }
}

.left {
    position: fixed;
    z-index: 10000;
    height: 100%;
    width: 30%;
    
    @include media('<=tablet') {
        background-color: var(--col-white);
        width: 100%;
        left: -100%;

        body.is-menu-open & {
            left: 0;
        }
    }
    
}

.button {
    border: 1px solid;
    border-radius: 300px;
    padding: .2em 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}