.b-newsletter {
    font-size: var(--fs-medium);
    width: 100%;
    max-width: 700px;
    margin: 2rem 0;

    .inputs {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: .5em;
        margin-top: .4em;
        
        input[type="email"] {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid;
            padding: .2em 0;
            width: 100%;
        }
    }

}