.c-footer {
    padding: var(--gutter);
    font-size: var(--fs-small);

    @include media('>tablet') {
        margin-left: 30%;
    }

    .footer-pages {
        a {
            &:not(:last-of-type)::after {
                content: " •"
            }
        }
    }
}