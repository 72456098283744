/* gets class .c-text */

.b-text {
    .small {
        font-size: var(--fs-small);
    }
    .medium {
        font-size: var(--fs-medium);
    }
    .large {
        font-size: var(--fs-large);
    }
}