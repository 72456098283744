.s-menu {
    padding: 1rem;

    @include media('<=tablet') {
        padding-top: 3em;
    }

    .menu {
        &_element {
            &.is-active {
                text-indent: 1rem;
            }
        }
    }

    .lang {
        margin-top: .2em;
        &_list {
            display: flex;
            gap: var(--indentation);
        }
        &_element {
            opacity: .5;
            &.is-active {
                opacity: 1;
            }
        }
    }
}