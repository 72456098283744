.b-heading {
    &:not(:first-child) {
        padding-top: 1em;
    }
    h2 {
        &.position-left {
            font-size: .65em;
            margin-bottom: 1em;
            @include media('>tablet') {
                max-width: 120px;
                position: absolute;
                left: 20%;
            }
        }
        &.position-center {
            margin-bottom: 1em;
        }
    }
}