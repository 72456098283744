.p-program {
    
    .program {
        grid-column: span 5;

        @include media ('>tablet') {
            margin-left: -140px;
        }
    }

    .program-element {
        &:not(:hover).is-highlighted {
            color: gray;
        }
    }
    
    .years-nav {
        position: sticky;
        top: 1rem;
    }

    .year {
        @include media ('>tablet') {
            display: grid;
            grid-template-columns: 140px 1fr;
        }
        margin-bottom: var(--gutter);
        
        h1 {
            font-size: var(--fs-small);
            padding-bottom: var(--gutter);
            height: var(--fs-small);
            top: var(--gutter);
            
            @include media ('>tablet') {
                position: sticky;
                padding-bottom: 5em;
            }
        }

        &_category {
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: .5em;
                top: -.7em;
                width: .4em;
                height: 1.2em;
                border-left: 1px solid;
                border-bottom: 1px solid;
                border-bottom-left-radius: 2px;
                z-index: -1;
                color: lightgray;
            }
        }
    }

    .year:not(:first-of-type) {
        // padding-top: 1rem;
    }
}
