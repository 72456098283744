.s-exhibition-header {
    margin-left: var(--indentation);
    text-indent: calc(-1 * var(--indentation));
    max-width: 45ch;

    .title {
        font-style: italic;
    }

    .meta {
        // font-family: monospace;
        margin-top: .2em;
        // margin-bottom: .3em;
        font-size: var(--fs-small);
        // text-transform: uppercase;
        // letter-spacing: .2ch;
        display: flex;
        text-indent: 0;
        margin-left: 0;

        &-date {
            margin-left: var(--indentation);
        }
    }
}