.c-text {
    max-width: 55ch;
    font-size: var(--fs-medium);

    &-small {
        font-size: var(--fs-small);
    }

    p {
        margin-bottom: 1rem;
    }

    a {
        border-bottom: 1px dotted;
    }

    ul {
        list-style-type: disc;
        padding-left: 1em;
        margin-bottom: 1em;
    }

    li {
        margin-left: 1em;
        padding-left: .5em;
    }

    figure.video {
        max-width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;

        iframe {
            width: 100%;
            aspect-ratio: 16/9;
        }
    }

    counter-set: footnoteNumber;

    article-footnote {
        counter-increment: footnoteNumber;
        &::after {
            // content: counter(footnoteNumber);
            content: '1';
            margin-left: .5ch;
            margin-bottom: -20px;
            border: 1px dotted;
            font-size: var(--fs-small);
            min-width: 1em;
            height: var(--fs-medium);
            padding: 0 .2em;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 10em;
        }
    }
}