.c-footnotes {
    margin-top: 4em;
    .footnote {
        display: flex;
        gap: 4em;
        margin-bottom: 1em;
        &_index {
            // text-indent: var(--indentation);
            // margin-bottom: -20px;
            // border: 1px dotted;
            // font-size: var(--fs-small);
            // min-width: 1em;
            // height: var(--fs-medium);
            // padding: 0 .2em;
            // display: inline-flex;
            // justify-content: center;
            // align-items: center;
            // border-radius: 10em;
        }
        &_text {
            // text-indent: var(--indentation);
            max-width: 60ch;
        }
    }
}