.c-menu-button {
    padding: var(--gutter);
    border-bottom: 1px solid;
    width: 100%;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 30000;
    background: var(--col-white);

    @include media ('>tablet') {
        display: none;
    }
}