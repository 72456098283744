main.p-home {
    max-width: 100%;
}

.layout-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 2rem;
    gap: calc(var(--gutter) * 2);

    .column {
        grid-column: span 12;
        @include media('>tablet') {
            grid-column: span var(--span);
        }

        &--empty {
            display: none;
            @include media('>tablet') {
                display: block;
            }
        }
    }
}