.fancybox__container {
    --fancybox-bg: var(--col-white);
    --fancybox-color: var(--col-black);
    --carousel-button-shadow: none;
    --carousel-button-svg-filter: none;

    right: 300px;
    background-color: white;
}

.fancybox__toolbar {
    background: transparent;
}

.fancybox__nav {
    display: none;
}

.fancybox__slide {
    // padding: calc(var(--gutter) * 2);
    // justify-content: space-between;

    &::before, &::after {
        // display: none;
    }
}

.fancybox__button--close {
    &::after {
        content: "Close";
        font-size: var(--fs-small);
    }
    svg {
        display: none;
    }
}

.fancybox__caption {
    line-height: inherit;
    bottom: var(--gutter);
    font-size: var(--fs-small);
}