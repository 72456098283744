.s-gallery {

    height: 100px;
    position: relative;

    @include media('>=640px') {
        position: fixed;
        top: 0;
        right: 30px;
        height: 100vh;
        width: 15%;
    }
    
    
    @include media('>desktop') {
        right: calc(var(--gutter) * 4);
        width: 200px;
    }

    &_slide {
        width: 66px;
        &-landscape {
            width: 100px;
        }
        height: 100%;

        @include media('>=640px') {
            height: auto;
            width: unset;
            max-width: 100%;
        }

        margin-bottom: var(--gutter);
        cursor: pointer;
        transition: opacity .05s ease;

        body[data-lightbox-open="true"] & {
            opacity: .6;

            &.swiper-slide-active {
                opacity: 1;
            }
        }

        .s-gallery:hover & {
            opacity: .6;

            &:hover {
                opacity: 1;
            }
        }
        
        &_transform-container {
            transform: translate(var(--tx), var(--ty)) rotate(var(--rotation));
        }

        &-portrait {
            max-width: 66%;
        }
    }
}

.swiper-button {
    position: absolute;
    right: 50%;
    left: unset;
    transform: translateX(50%);
    width: 20px;
    height: 20px;
    color: black;
    // border: 1px solid lightgray;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.2));
    display: none;

    &-disabled {
        filter: none;
    }

    &-prev {
        top: 40px;
    }
    &-next {
        top: unset;
        bottom: 20px;
    }

    &-prev::after, &-next::after {
        display: none;
        transform: rotate(90deg);
        font-size: 16px;
    }
}