.u-fs-small {
    font-size: var(--fs-small);
}

.u-fs-1, .u-fs-regular, .u-fs-medium {
    font-size: var(--fs-medium);
}

.u-fs-2, .u-fs-large {
    font-size: 2rem;
}

.u-indented {
    padding-left: var(--indentation);
}

.u-hide-on-mobile {
    @include media('<=tablet') {
        display: none;
    }
}