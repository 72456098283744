.s-lightbox {
    position: fixed;
    z-index: 40000;
    top: 0;
    left: 0;
    width: 100%;

    @include media('>=640px') {
        width: 75%;
    }

    height: 100%;
    background-color: var(--col-white);

    @include media('>desktop') {
        width: calc(100% - 300px)
    }

    display: none;
    body[data-lightbox-open="true"] & {
        display: block;
    }

    &_close-button {
        cursor: pointer;
        position: fixed;
        z-index: 1000;
        top: var(--gutter);
        left: var(--gutter);
        font-size: var(--fs-large);
    }

    figure {
        width: 100%;
        height: 100%;

        .s-lightbox_image-container {
            width: 100%;
            height: 100%;
            max-height: 600px;
            max-width: 800px;
            margin: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            opacity: 0;
            transition: opacity .2s ease;

            &.swiper-lazy-loaded {
                opacity: 1;
            }
        }

        figcaption {
            font-size: var(--fs-small);
            position: absolute;
            left: var(--gutter);
            bottom: var(--gutter);
        }

    }

}