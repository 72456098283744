.s-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: var(--gutter);

    img {
        width: 65px;
        margin-bottom: var(--gutter);
    }

    .text {
        font-size: var(--fs-small);
    }

    footer & {
        display: none;

        @include media('<=tablet') {
            display: block;
            position: relative;
        }
    }
}